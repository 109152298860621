import { useLazyQuery, useQuery } from "@apollo/client"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { GET_PROJECT } from "../utils/graphqlOperations"
import ProjectDetailChart from "./ProjectDetailChart"
import ProjectDetailTable from "./ProjectDetailTable"
import Papa from "papaparse"
import moment from "moment"

const ProjectDetail = () => {
    let { projectId } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [tabActive, setTabActive] = useState("visualization")

    useEffect(() => {
        // read the params on component load and when any changes occur
        const currentParams = Object.fromEntries([...searchParams]);
        // get new values on change
        console.log('useEffect:', currentParams);
        const currenttab = currentParams.tab ? currentParams.tab : "visualization"
        setTabActive(currenttab)
    }, [searchParams]);

    const goToTab = (tab) =>{
        setTabActive(tab)
        navigate({
            pathname: `../../projects/${projectId}`,
            search: `?tab=${tab}`,
        })
    }

    const {data: projectData, loading:projectLoading } = useQuery(GET_PROJECT, {
        fetchPolicy: 'network-only',
        variables: {
            input: `${projectId}`
        }
    })

    const [
        getDowloadCsv
    ] = useLazyQuery(GET_PROJECT, {
        fetchPolicy: 'network-only'
    })

    const downloadCsv = () => {
        getDowloadCsv({
            variables: {
                input: `${projectId}`
            }
        })
        .then(res => {
            const jsonData = res.data.accountsget.account.map(el => {
                el["Account Name"] = el.accountName
                el["Category"] = el.newBucket
                el["Reputation"] = `${Math.round(el.reputation)}%`
                el["Relationship"] = `${Math.round(el.relationship)}%`
                el["Revenue"] = `${Math.round(el.revenue)}%`
                el = _.omit(el,["__typename","aid","bucket","attractiveness","propensity","accountName","newBucket","reputation","relationship","revenue"])
                return el
            })

            const csv = Papa.unparse(jsonData,{
                header: true,
                quotes: false,
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ",",
                newline: "\r\n",
                skipEmptyLines: false,
                columns: null
            })

            const element = document.createElement("a");
            const file = new Blob([csv], {type: 'text/csv'});
            element.href = URL.createObjectURL(file);
            element.download = `${res.data.accountsget.name} - ${moment().format("DD-MM-YYYY HH:mm:ss")}.csv`;
            document.body.appendChild(element);
            element.click();
            element.remove()
        })
    }

    return <>
        <div className="project-head">
            <div className="container">
                <div className="row">
                    <div className="col6">
                        {/* <ul>
                            <li onClick={() => goToTab("visualization")} className={tabActive === "visualization" ? "active" : ""}>VISUALISATION</li>
                            <li onClick={() => goToTab("datalist")} className={tabActive === "datalist" ? "active" : ""}>DATA LIST</li>
                        </ul> */}
                    </div>
                    {
                        !projectLoading && <div className="col6" style={{display: "flex"}}>
                            <div className="download" onClick={downloadCsv}>
                                <div className="txt">DOWNLOAD {/*<span>{projectData.accountsget.name}</span>*/}</div>
                                <svg viewBox="0 0 21.78 25.93"><path d="M21.02 11.14h-1.27V2.28c0-1.26-1.02-2.28-2.28-2.28H6.84c-.2 0-.4.08-.54.22L.22 6.3c-.14.14-.22.34-.22.54v16.82c0 1.26 1.02 2.28 2.28 2.28h15.2c1.26 0 2.28-1.02 2.28-2.28v-.86h1.26c.42 0 .76-.34.76-.76V11.9c0-.42-.34-.76-.76-.76zM6.08 2.59v2.72c0 .42-.34.76-.76.76H2.59l3.49-3.48zm-.76 5c1.26 0 2.28-1.02 2.28-2.28v-3.8h9.88c.42 0 .76.34.76.76v8.86H1.52V7.58h3.8zm12.91 16.06c0 .42-.34.76-.76.76H2.27c-.42 0-.76-.34-.76-.76v-.86h16.72v.86zm2.03-2.38H1.52v-8.61h18.74v8.61zM4.13 15.48c.82-1.22 2.47-1.54 3.69-.72.29.2.37.59.17.88-.2.29-.59.36-.88.17-.23-.16-.5-.24-.78-.24-.77 0-1.39.62-1.39 1.39 0 .77.62 1.39 1.39 1.39.25.01.5-.08.69-.24.04-.03.07-.06.1-.1.22-.27.62-.3.89-.08.27.22.3.62.08.89-.43.52-1.08.82-1.76.8v-.01c-.53 0-1.05-.16-1.49-.45-1.21-.81-1.53-2.47-.71-3.68zm5.09 3.34c-.23-.26-.21-.65.04-.88.26-.24.66-.23.89.03.25.25.59.4.94.39.25.03.49-.12.58-.35.03-.17-.21-.32-.45-.41-.63-.23-1.19-.48-1.21-.49-.03-.01-.06-.03-.09-.05-.47-.3-.71-.85-.63-1.4.09-.6.53-1.09 1.12-1.25.84-.28 1.76-.02 2.32.66.16.27.09.62-.16.81-.28.21-.67.16-.89-.11-.26-.18-.6-.24-.91-.14-.11.02-.21.11-.23.22-.01.05.01.1.04.14.16.07.6.26 1.08.43.83.19 1.37.98 1.26 1.82-.19.85-.96 1.43-1.83 1.4-.71-.01-1.39-.3-1.87-.82zm4.77-3.69c-.11-.33.07-.69.4-.8s.69.07.8.4l.86 2.48.82-2.47c.12-.31.45-.47.77-.38.34.1.53.45.43.78l-1.32 3.98c-.1.3-.37.5-.68.5-.31 0-.58-.19-.68-.48l-1.39-4c-.01 0-.01 0-.01-.01z" /></svg>
                            </div>
                        </div> 
                    }
                </div>
            </div>
        </div>
        <main className="projectDetailWrap">
        {
            <ProjectDetailChart data={projectData?.accountsget || {accounts:[]}} loading={projectLoading} />
        }
        {
            <ProjectDetailTable data={projectData?.accountsget || {cid:"",accounts:[]}} loading={projectLoading} />
        }
        </main>
    </>
}

export default ProjectDetail
