import { useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import { Link } from "react-router-dom"
import { DELETE_PROJECT, GET_PROJECTS } from "../utils/graphqlOperations"

import _ from "lodash"

const Projects = () => {

    const {data: projectData, loading:projectLoading, refetch: projectRefetch} = useQuery(GET_PROJECTS,{
        variables:{
            query: {
                active: true,
            }
        }
    })

    const [deleteProject] = useMutation(DELETE_PROJECT)

    const deleteProjectHandler = (project) => {
        const data = {...project}
        const cid = data.cid.cid
        delete data.__typename
        data.cid = {link: cid}
        deleteProject({
            variables: {
                query: {
                    pid: data.pid
                },
                data: {
                    ...data,
                    active: false
                }
            }
        })
        .then(() => {
            projectRefetch()
        })
    }

    return <>
    
    <div className="new-project">
        <div className="container">
            Create New Projects <Link to={"/projects/new"}>CREATE PROJECT</Link>
        </div>
    </div>

    <div className="recent-project">
        <div className="container">

            <div className="head">
                <div className="row">
                    <div className="col6">
                        <h2>Recent Projects</h2>
                    </div>
                    {/* <div className="col6 search">
                        <input type="text" className="search-input"/>
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24.771 24.771">
                            <path id="Search" d="M17.122,8.16a.576.576,0,1,1-.815.815,5.191,5.191,0,0,0-7.333,0A.576.576,0,0,1,8.16,8.16,6.344,6.344,0,0,1,17.122,8.16ZM28.771,26.467A2.3,2.3,0,0,1,24.838,28.1l-6.049-6.049a.576.576,0,0,1,0-.815l.815-.815-1.277-1.277a8.656,8.656,0,1,1,.815-.815L20.418,19.6l.815-.815a.576.576,0,0,1,.815,0L28.1,24.838A2.287,2.287,0,0,1,28.771,26.467ZM20.13,12.641a7.489,7.489,0,1,0-7.489,7.489A7.5,7.5,0,0,0,20.13,12.641Zm7.489,13.826a1.144,1.144,0,0,0-.338-.815L21.64,20.011,20.011,21.64l5.642,5.642a1.179,1.179,0,0,0,1.629,0,1.144,1.144,0,0,0,.338-.815Z" transform="translate(-4 -4)"/>
                        </svg>
                    </div> */}
                </div>
            </div>

            <div className="table-wrap">

                <div className="table-row-wrap table-row-head">
                    <div className="table-col1">No.</div>
                    <div className="table-col2">Project Name</div>
                    <div className="table-col3">Client Name</div>
                    <div className="table-col4">Created Date</div>
                    <div className="table-col5 center">No. Of Accounts</div>
                    <div className="table-col6 action">Actions</div>
                </div>
                {
                    !projectLoading && projectData.projects.map((el,i) => {
                        return (
                            <div className="table-row-wrap" key={el.pid}>
                                <div className="table-col1">{i + 1}</div>
                                <div className="table-col2">{el.name}</div>
                                <div className="table-col3">{el.client}</div>
                                <div className="table-col4">{moment(el.createdDate).format("DD MMM YYYY")}</div>
                                <div className="table-col5 center">{_.size(el.cid.accounts)}</div>
                                <div className="table-col6 action">
                                    <Link to={`/projects/${el.pid}`}>
                                        <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.074 25.074"><path data-name="Path 1" d="M24.906 12.07a17.2 17.2 0 0 0-12.37-6.017c-5.285 0-10.11 3.262-12.37 6.017a.737.737 0 0 0 0 .936 17.182 17.182 0 0 0 12.37 6.017c5.385 0 10.19-3.361 12.37-6.017a.738.738 0 0 0 0-.936Zm-7.36.468a5.01 5.01 0 1 1-5.01-5.01 5.015 5.015 0 0 1 5.01 5.01Zm-15.813 0a17.813 17.813 0 0 1 5.641-3.919 6.468 6.468 0 0 0 0 7.838 17.487 17.487 0 0 1-5.64-3.918ZM17.7 16.457a6.468 6.468 0 0 0 0-7.838 17.49 17.49 0 0 1 5.64 3.918 17.811 17.811 0 0 1-5.64 3.92Z"/><path data-name="Path 2" d="M10.029 12.537a2.507 2.507 0 1 0 2.507-2.507 2.51 2.51 0 0 0-2.507 2.507Zm3.54 0a1.032 1.032 0 1 1-1.032-1.032 1.034 1.034 0 0 1 1.032 1.032Z"/><path data-name="Path 3" d="M12.536 3.2a.738.738 0 0 0 .737-.737V.737a.7375.7375 0 0 0-1.475 0v1.721a.738.738 0 0 0 .738.742Z"/><path data-name="Path 4" d="M16.935 4.182a.738.738 0 0 0 1.006-.275l.981-1.721a.7374.7374 0 1 0-1.281-.731l-.981 1.721a.738.738 0 0 0 .275 1.006Z"/><path data-name="Path 5" d="M7.131 3.907a.7374.7374 0 1 0 1.281-.731l-.981-1.721a.7374.7374 0 0 0-1.281.731Z"/><path data-name="Path 6" d="M12.536 21.879a.738.738 0 0 0-.737.737v1.721a.7375.7375 0 1 0 1.475 0v-1.721a.738.738 0 0 0-.738-.737Z"/><path data-name="Path 7" d="M8.137 20.893a.738.738 0 0 0-1.006.275l-.981 1.721a.7374.7374 0 0 0 1.281.731l.981-1.721a.737.737 0 0 0-.275-1.006Z"/><path data-name="Path 8" d="M17.942 21.167a.7374.7374 0 1 0-1.281.731l.981 1.721a.7374.7374 0 1 0 1.281-.731Z"/></svg>
                                    </Link>
                                    <button onClick={()=>deleteProjectHandler(el)}><svg width="14" height="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.049 17.171"><path data-name="Path 9" d="M12.487 4.683a.78.78 0 0 0-.78.78v8.734a1.5 1.5 0 0 1-1.569 1.412H3.91a1.5 1.5 0 0 1-1.569-1.412V5.463a.7805.7805 0 1 0-1.561 0v8.734a3.058 3.058 0 0 0 3.13 2.973h6.228a3.058 3.058 0 0 0 3.13-2.973V5.463a.78.78 0 0 0-.781-.78Z"/><path data-name="Path 10" d="M13.268 2.341h-3.122V.78a.78.78 0 0 0-.78-.78H4.683a.78.78 0 0 0-.78.78v1.561H.78a.7805.7805 0 0 0 0 1.561h12.488a.7805.7805 0 1 0 0-1.561Zm-7.8 0v-.78H8.59v.78Z"/><path data-name="Path 11" d="M6.244 12.488V7.024a.7805.7805 0 0 0-1.561 0v5.463a.7805.7805 0 0 0 1.561 0Z"/><path data-name="Path 12" d="M9.366 12.488V7.024a.7805.7805 0 1 0-1.561 0v5.463a.7805.7805 0 1 0 1.561 0Z"/></svg></button>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    </div>

    </>
}

export default Projects