import { Link, useNavigate } from "react-router-dom"
// import getitLogo from "../images/getit-logo.png"
import hwLogo from "../images/hotwire-logo.png"

const Header=()=>{
    const navigate = useNavigate()
    const logout = () => {
        localStorage.removeItem("uid")
        navigate("/login")
    }
    const isLoggedIn = localStorage.getItem('uid')
    return (
        <div className="header">
            <div className="container">
                <div className="row align-center">
                    {/* <div className="col6">
                        <img src={avavtLogo} width="148" alt=""/>
                    </div> */}
                    <div className="col6 "> {/* rightLogo */}
                        <Link to={"/projects"}><img src={hwLogo} width='125' alt=""/></Link>
                    </div>
                    {
                        isLoggedIn ? 
                            <div className="col6 ">
                                <div className="logout"><button onClick={() => logout()}>Logout</button></div>
                            </div>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Header