import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useDeferredValue } from 'react';

require("highcharts/modules/exporting")(Highcharts);

const ProjectDetailChart = ({data, loading}) => {
    const series = data.account && data.account.map(el => {
        return {
            x: parseInt(el.propensity,10),
            y: parseInt(el.attractiveness,10),
            custom: {
                name: el.accountName,
                reputation: el.reputation,
                relationship: el.relationship,
                revenue: el.revenue
            }
        }
    })
    const options = {
        chart: {
            type: 'scatter',
            backgroundColor: "#f1f1f1",
            marginTop: 60,
            height: 600,
            events: {
                load: function() {
                    const chart = this;
                    const width = chart.plotBox.width / 2.0;
                    const height = chart.plotBox.height / 2.0 + 1;
                    chart.renderer.text('1', chart.plotBox.x + width + (width/2), 90).attr({zIndex:6}).css({color: '#99B967',fontSize: '25px', fontWeight: 'bold'}).add();
                    chart.renderer.text('2', chart.plotBox.x + width + (width/2), chart.plotBox.y + (2* height) - 20).attr({zIndex:6}).css({color: '#30ADC4',fontSize: '25px', fontWeight: 'bold'}).add();
                    chart.renderer.text('3', chart.plotBox.x + (width/2), 90).attr({zIndex:6}).css({color: '#E8A659',fontSize: '25px', fontWeight: 'bold'}).add();
                    chart.renderer.text('4', chart.plotBox.x + (width/2), chart.plotBox.y + (2* height) - 20).attr({zIndex:6}).css({color: '#EF8888',fontSize: '25px', fontWeight: 'bold'}).add();
                    chart.renderer.rect(chart.plotBox.x, chart.plotBox.y, width, height, 1)
                        .attr({
                            fill: '#FFECE5',
                            zIndex: 0
                        })
                        .add();
                    chart.renderer.rect(chart.plotBox.x + width, chart.plotBox.y, width, height, 1)
                        .attr({
                            fill: '#EEF2E7',
                            zIndex: 0
                        })
                        .add();
                    chart.renderer.rect(chart.plotBox.x, chart.plotBox.y + height, width, height, 1)
                        .attr({
                            fill: '#FFE5E5',
                            zIndex: 0
                        })
                        .add();
                    chart.renderer.rect(chart.plotBox.x + width, chart.plotBox.y + height, width, height, 1)
                        .attr({
                            fill: '#E5FFF8',
                            zIndex: 0
                        })
                        .add(); 
                }
            }
        },
        title:{
            text: null
        },
        xAxis: {
            gridLineWidth: 1,
            tickInterval: 10,
            min: 0,
            max: 70,
            lineWidth: 3,
            lineColor: '#1D2449',
            offset: 0,
            labels: {
                enabled: false
            },
            tickLength: 0,
            title: {
                text: "Account Value",
                style: {
                    fontSize: "14px",
                    fontWeight: "500"
                }
            },
            plotLines: [{
                id: 'ver',
                color: '#707070',
                width: 1,
                value: 35,
                zIndex: 1
        
            }]
        },
        yAxis: {
            tickInterval: 10,
            min: 0,
            max: 70,
            lineWidth: 3,
            lineColor: '#1D2449',
            offset: 0,
            labels: {
                enabled: false
            },
            tickLength: 0,
            title: {
                text: "Account Influence",
                style: {
                    fontSize: "14px",
                    fontWeight: "500"
                }
            },
            plotLines: [{
                id: 'ver',
                color: '#707070',
                width: 1,
                value: 35,
                zIndex: 1
        
            }]
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false
                },
                marker: {
                    fillColor: "rgba(29,36,73,0.5)",
                    radius: 10,
                    states:{
                        hover:{
                            fillColor: "rgba(29,36,73,1)",
                            radiusPlus:0
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            backgroundColor: "#FFFFFF",
            borderColor: "transparent",
            shadow: false,
            shape: "rect",
            formatter: function() {
                return `<b>${ this.point.custom.name}</b><br>Reputation: <b>${ Math.round(this.point.custom.reputation)}%</b><br>Relationship: <b>${ Math.round(this.point.custom.relationship)}%</b><br>Revenue: <b>${ Math.round(this.point.custom.revenue)}%</b>`
            }
        },
        series: [{
            data: series
        }],
        navigation: {
            buttonOptions: {
                height: 40,
                width: 44,
                symbolSize: 24,
                symbolX: 23,
                symbolY: 21,
                symbolStrokeWidth: 2,
                theme: {
                    fill: "#f1f1f1"
                }
            }
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
                }
            }
        }
    }

    const deferedLoad = useDeferredValue(loading)
    return <div className='chartWrap'><div className="container">
        {!deferedLoad && <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />}
    </div></div>
}

export default ProjectDetailChart