import { useRef, useState } from "react"
import Papa from "papaparse"
import _ from "lodash"
import {v4 as uuidv4} from "uuid"
import moment from "moment"
import { useMutation } from "@apollo/client"
import { ADD_PROJECT, UPLOAD_CSV } from "../utils/graphqlOperations"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

const NewProject = () => {

    const [fileUploaded, setFileUploaded] = useState(false)
    const [fileUploadedSet, setFileUploadedSet] = useState("")
    const [loading, setLoading] = useState(false)
    const [uploadError, setUploadError] = useState("")

    const navigate = useNavigate()

    const fileUpload = useRef()

    const { register, handleSubmit, trigger, formState: {errors} } = useForm({
        defaultValues: {
            pid:"",
            cid:"",
            uid: {link: localStorage.getItem("uid")},
            createdDate:"",
            name: "",
            client: "",
            active: true
        }
    })

    const [addProject] = useMutation(ADD_PROJECT)

    const createProject = (data,csvId) => {
        data.pid = uuidv4()
        data.createdDate = moment().toISOString()
        data.cid = {link: csvId}
        addProject({
            variables: {
                data
            }
        })
        .then(({data: {insertOneProject: {pid}}}) => {
            navigate(`/projects/${pid}`)
        })
    }

    const [uploadCsv] = useMutation(UPLOAD_CSV)
    
    const parseFile = () => {
        setUploadError("")
        trigger()
        if(!fileUploadedSet){
            setUploadError("Upload a CSV file to proceed!")
            return
        }
        Papa.parse(fileUploadedSet,{
            header: false,
            skipEmptyLines: true,
            complete: function (results, file) {
                if (!results.errors.length) {
                    let massagedData = _.map(_.drop(results.data,3),function(el){
                        const attractiveness = parseInt(el[1],10) + parseInt(el[2],10) + parseInt(el[3],10)
                        const propensity = parseInt(el[4],10) + parseInt(el[5],10) + parseInt(el[6],10) + parseInt(el[7],10) + parseInt(el[8],10) + parseInt(el[9],10) + parseInt(el[10],10)
                        const reputation = (((parseInt(el[5],10) + parseInt(el[6],10) + parseInt(el[7],10)) / 30) * 100).toString()
                        const relationship = (((parseInt(el[4],10) + parseInt(el[8],10) + parseInt(el[10],10)) / 30) * 100).toString()
                        const revenue = (((parseInt(el[9],10) + parseInt(el[10],10)) / 20) * 100).toString()
                        const bucket = attractiveness >= 35 && propensity >= 35 ? "One-To-One" : attractiveness < 35 && propensity >= 35 ? "One-To-Few" : attractiveness < 35 && propensity < 35 ? "Demand Gen" : "One-To-Many"
                        const newBucket = bucket
                        return {
                            aid: uuidv4(),
                            accountName: el[0],
                            priceValue: el[1],
                            oppurtunity: el[2],
                            stratergicVal: el[3],
                            currentClient: el[4],
                            brandAwareness: el[5],
                            brandAssociation: el[6],
                            brandPerception: el[7],
                            buyingCenterCoverage: el[8],
                            competetion: el[9],
                            executiveEngagement: el[10],
                            industry: el[11],
                            country: el[12],
                            attractiveness,
                            propensity,
                            reputation,
                            relationship,
                            revenue,
                            bucket,
                            newBucket
                        }
                    })
                    massagedData = _.uniqBy(massagedData, "accountName")
                    const csvData = {
                        cid: uuidv4(),
                        createdDate: moment().toISOString(),
                        updatedDate: moment().toISOString(),
                        accounts: massagedData
                    }
                    uploadCsv({
                        variables: {
                            data: csvData
                        }
                    })
                    .then(({data: {insertOneCsv: {cid}}}) => {
                        handleSubmit(async (data) => await createProject(data,cid))()
                    })
                }
            }
        })
    }

    const droppedFile = (e) => {
        setUploadError("")
        setLoading(true)
        e.stopPropagation()
        const interval = setInterval(() => {
            if(e.target.files[0]){
                clearInterval(interval)
                setLoading(false)
                if(e.target.files[0].size > 5242880){
                    setUploadError("File size greater than 5MB.")
                    return
                }
                if(e.target.files[0].type !== "text/csv"){
                    setUploadError("Can only upload CSV files")
                    return
                }
                setFileUploadedSet(e.target.files[0])
                setFileUploaded(true)
            }
        },1000)
    }

    return <>

        <div className="create-project-wrap">
            <div className="container">
                <h2>Create New Projects</h2>

                <div className="row">
                    <div className="col4 title">Project Name</div>
                    <div className="col8">
                        <input type="text" className="text" {...register("name",{required: true})} />
                        {errors.name && <span className="error">This field is required</span>}
                    </div>
                </div>

                <div className="row">
                    <div className="col4 title">Client Name</div>
                    <div className="col8">
                        <input type="text" className="text" {...register("client",{required: true})} />
                        {errors.client && <span className="error">This field is required</span>}
                    </div>
                </div>

                <div className="row">
                    <div className="col4 title">Upload Account Details (CSV)</div>
                    <div className="col8">
                        <div className="upload-wrap">
                            {
                                loading ? <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 52 52" className="loading"><circle style={{fill:"none",stroke:"#000",strokeWidth:2,strokeMiterlimit:10}} cx="26" cy="26" r="25"/></svg> :
                                <>
                                    <input className="box__file" type="file" id="file" accept=".csv" ref={fileUpload} onDrop={droppedFile} onChange={droppedFile} title="Drag & Drop or Browse CSV File" />
                                    {
                                        fileUploaded && <span className="uploadname">{fileUploadedSet.name}</span>
                                    }
                                    <label htmlFor="file" className="box_label" title="Drag & Drop or Browse CSV File">Drag & Drop CSV File<br /> Or</label>
                                    <button className="box__button" type="submit">BROWSE CSV FILE</button>
                                </>
                            }
                            
                        </div>
                        {uploadError && <span className="error">{uploadError}</span>}
                        <div className="create-project-btn">
                            <button onClick={() => parseFile()}>Create Project</button>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </>
}

export default NewProject