import gql from "graphql-tag";

export const USER_LOGIN = gql`
    query userLogin($query: UserQueryInput!){
        user(query: $query){
            loginId
            uid
        }
    }
`

export const GET_PROJECTS = gql`
    query getProjects($query: ProjectQueryInput){
        projects(query: $query, sortBy: CREATEDDATE_DESC){
            pid
            name
            client
            createdDate
            cid{
                cid
                accounts {
                    accountName
                    attractiveness
                    propensity
                    reputation
                    relationship
                    revenue
                }
            }
        }
    }
`

export const GET_PROJECT = gql`
    query getProject($input: String){
        accountsget(input: $input) {
            name
            cid
            account {
                aid
                accountName
                attractiveness
                propensity
                relationship
                reputation
                revenue
                bucket
                newBucket
                industry
                country
            }
        }
    }
`

export const DELETE_PROJECT = gql`
    mutation deleteProject($query: ProjectQueryInput!,$data:ProjectUpdateInput!){
        updateOneProject(query: $query,set: $data){
            pid
            name
            client
            createdDate
            cid{
                accounts {
                    accountName
                    attractiveness
                    propensity
                    reputation
                    relationship
                    revenue
                }
            }
        }
    }
`

export const ADD_PROJECT = gql`
    mutation addProject($data:ProjectInsertInput!){
        insertOneProject(data: $data){
            pid
        }
    }
`

export const UPLOAD_CSV = gql`
    mutation uploadCsv($data: CsvInsertInput!){
        insertOneCsv(data: $data){
            cid
        }
    }
`

export const UPDATE_CSV = gql`
    mutation uploadCsv($query:CsvQueryInput, $data: CsvUpdateInput!){
        updateOneCsv(query: $query, set: $data){
            cid
        }
    }
`